import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import HelmetComponent from '../components/helmetcomponent';
import chunk from 'lodash/chunk';
import Img from 'gatsby-image';

export default function Template({ data }) {
  const {
    headerImage,
    title,
    description,
    staffComposition,
    staffCompositionHeading,
    directorImage,
    directorName,
    directorPosition,
    directorBio,
    teamHeading,
    teamDesc,
    teamImg,
    bePartHeading,
    bePartDesc,
    buttonLightLink,
    buttonLightText,
    buttonPurpleLink,
    buttonPurpleText
  } = data.allContentfulPageMeetTheTeam.edges[0].node;
  let staffCompositionDiv = '';

  // we are doing this so that we can divide the list of staff into 3 columns when displayed
  let chunkedArr = chunk(staffComposition, Math.ceil(staffComposition.length / 3));
  for (let i = 0; i < chunkedArr.length; i++) {
    staffCompositionDiv += `<div class="col-md-4 gray">`;
    chunkedArr[i].map(position => {
      staffCompositionDiv += `<p key="${position}">${position}</p>`;
    });
    staffCompositionDiv += `</div>`;
  }

  return (
    <Layout>
      <HelmetComponent title={title} description={description} image={headerImage.fluid.src} />
      <section id="sponsor-hero">
        <div id="page-sub-header" className="hero">
          {/* first <Img> is the purple overlay */}
          <Img
            fluid={headerImage.fluid}
            style={{ position: 'absolute' }}
            className="w-100 bg-img-overlay"
          />
          <Img
            fluid={headerImage.fluid}
            style={{ position: 'absolute' }}
            className="w-100 bg-img"
          />
          <div className="container">
            <div className="page-sub-header-content">
              <div className="row">
                <div className="col-md-7 col-xs-12">
                  <h1>{title}</h1>
                  <p>{description.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="primary" className="content-area">
        <main id="main" className="site-main mt-20" role="main">
          <section id="staff" className="mb-5 mt-5">
            <div className="container">
              <div className="row d-flex justify-content-center">
                <div className="col-md-8 text-center">
                  <span className="f24 gray">{staffCompositionHeading}</span>
                </div>
              </div>
              <div className="row d-flex justify-content-center mt-5 mb-5">
                <div className="col-md-10">
                  <div
                    className="row f24"
                    dangerouslySetInnerHTML={{ __html: staffCompositionDiv }}
                  ></div>
                </div>
              </div>
            </div>
          </section>
          <section id="section-director" className="mt200">
            <div className="container">
              <div className="row d-flex justify-content-between">
                <div className="col-md-5">
                  <Img fluid={directorImage.fluid} className="w-100" alt="The director" />
                </div>
                <div className="col-md-6">
                  <h2 className="f48 black">{directorName}</h2>
                  <span className="purple f24 pt-2 pb-2">{directorPosition}</span>
                  <p className="f20 gray pt-2">{directorBio.directorBio}</p>
                </div>
              </div>
            </div>
          </section>
          <section id="the-team" className="mt200">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h2 className="f48 black">{teamHeading}</h2>
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-7">
                      <p className="f20 light-purple">{teamDesc.teamDesc}</p>
                    </div>
                  </div>
                </div>
              </div>
              <Img fluid={teamImg.fluid} className="w-80" alt="The team" />
            </div>
          </section>
          <section id="be-part" className="mt200">
            <div className="container text-center">
              <h1 className="f48 black">{bePartHeading}</h1>
              <div className="row d-flex justify-content-center">
                <div className="col-md-7">
                  <p className="f20 light-purple">{bePartDesc.bePartDesc}</p>
                </div>
              </div>
              <div className="mt40 d-flex w-100 justify-content-center">
                <a href={buttonLightLink} className="btn btn-purple2-inverse f14 mr-1">
                  {buttonLightText}
                </a>
                <a href={buttonPurpleLink} className="btn btn-purple2 f14 ml-1">
                  {buttonPurpleText}
                </a>
              </div>
            </div>
          </section>
        </main>
      </section>
    </Layout>
  );
}

export const meetTheTeamPageQuery = graphql`
  query MeetTheTeamPage($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        description
      }
    }
    allContentfulPageMeetTheTeam {
      edges {
        node {
          headerImage {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid
            }
          }
          title
          description {
            description
          }
          staffComposition
          staffCompositionHeading
          directorImage {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid
            }
          }
          directorName
          directorPosition
          directorBio {
            directorBio
          }
          teamHeading
          teamDesc {
            teamDesc
          }
          teamImg {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid
            }
          }
          bePartHeading
          bePartDesc {
            bePartDesc
          }
          buttonLightLink
          buttonLightText
          buttonPurpleLink
          buttonPurpleText
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
